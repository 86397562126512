<template>
  <div class="d-flex flex-grow-1 justify-content-center align-items-center">
    <Card ref="forgotPasswordRequest">
      <template #title>
        Reset password.
      </template>
      <template #main>
        <p>Enter the email you used to create your account.</p>
        <div class="form-floating">
          <input
            id="email"
            name="email"
            class="form-control mx-auto"
            type="email"
            :disabled="submitting"
            :placeholder="'Email'"
            :required="true"
            v-model="email"
            :class="{ 'is-invalid': safeGet(v$, 'email.$invalid') && safeGet(v$, 'email.$dirty') }"
            @change="v$.email.$touch()"
            @blur="v$.email.$touch()"
            @input="v$.email.$touch()"
            @keyup.enter="validateAndSubmit"
            autocomplete="email"
          />
          <label for="email"><span>Email</span></label>
          <span class="invalid-feedback" v-if="safeGet(v$, 'email.$error')">
            <fa-icon :icon="['fal', 'circle-exclamation']"></fa-icon>
            {{ v$.email.$errors[0].$message }}
          </span>
        </div>
      </template>
      <template #actions>
        <div class="d-flex justify-content-between">
          <BackButton :to="identityStore.loginUrl"></BackButton>
          <button
            class="btn btn-primary"
            :disabled="submitting || v$.$invalid || !v$.$dirty"
            @click="validateAndSubmit">
            <ButtonLoadingSpinner v-if="submitting"></ButtonLoadingSpinner>Continue
          </button>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { required, email } from '@vuelidate/validators'
import ButtonLoadingSpinner from '../marketplace/subComponents/ButtonLoadingSpinner'
import get from 'lodash/get'
import useVuelidate from '@vuelidate/core';
import { useIdentityStore } from '~/store/identity';
import BackButton from './BackButton';

export default {
  name: 'IdentityForgotPasswordChallenge',
  components: [
    ButtonLoadingSpinner,
    BackButton
  ],
  async setup() {
    return {
      v$: useVuelidate(),
      safeGet: get,
      identityStore:  useIdentityStore(),
    };
  },
  data() {
    return {
      email: '',
      submitting: false,
    };
  },
  validations() {
    return {
      email: { required, email },
    };
  },
  methods: {
    async validateAndSubmit() {
      this.v$.email.$touch();
      if (!this.v$.email.$invalid) {
        await this.beginAccountRecovery()
      }
    },
    async beginAccountRecovery() {
      this.submitting = true;
      try {
        await this.identityStore.accountRecoveryMethods(this.email);
      } catch (error) {
        if (this.$refs?.forgotPasswordRequest?.$refs?.alert) {
          this.$refs.forgotPasswordRequest.$refs.alert.openDanger({
            content: error.message ?? 'Password reset request failed. Please try again.',
            untrusted: true
          })
        }
        this.submitting = false
      }
    },
  },
}
</script>
