<template>
  <div class="d-flex flex-grow-1 justify-content-center align-items-center">
    <Card ref="ssnVerify">
      <template #title>
        Verify your identity.
      </template>
      <template #main>
        <p>Enter the last four digits of your social security number.</p>
        <div class="form-floating">
          <input
            id="ssnLastFour"
            name="ssnLastFour"
            class="form-control mx-auto"
            :type="!showSsnLastFour ? 'password' : 'text'"
            :disabled="submitting"
            :placeholder="'Enter the last four digits of your social security number'"
            :required="true"
            v-model="ssnLastFour"
            :class="{ 'is-invalid': safeGet(v$, 'ssnLastFour.$invalid') && safeGet(v$, 'ssnLastFour.$dirty') }"
            @change="v$.ssnLastFour.$touch()"
            @blur="v$.ssnLastFour.$touch()"
            @input="v$.ssnLastFour.$touch()"
            @keyup.enter="validateAndSubmit"
            inputmode="numeric"
            pattern="[0-9]"
            maxlength="4"
          />
          <span class="invalid-feedback" v-if="safeGet(v$, 'ssnLastFour.$error')">
            <fa-icon :icon="['fal', 'circle-exclamation']"></fa-icon>
            {{ v$.ssnLastFour.$errors[0].$message }}
          </span>
          <label for="ssnLastFour"><span>Verify the last four digits of your SSN</span></label>
          <span class="text-tooltip-icon" @click="showSsnLastFour = !showSsnLastFour">
            <fa-icon v-if="showSsnLastFour" :icon="['fal', 'eye-slash']"></fa-icon>
            <fa-icon v-else :icon="['fal', 'eye']"></fa-icon>
          </span>
        </div>
      </template>
      <template #actions>
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary"
            :disabled="submitting || v$.$invalid || !v$.$dirty"
            @click="validateAndSubmit">
            <ButtonLoadingSpinner v-if="submitting"></ButtonLoadingSpinner>Verify
          </button>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { required, numeric } from '@vuelidate/validators'
import ButtonLoadingSpinner from '../marketplace/subComponents/ButtonLoadingSpinner'
import get from 'lodash/get'
import useVuelidate from '@vuelidate/core';
import { useIdentityStore } from '~/store/identity';

export default {
  name: 'IdentitySsnVerification',
  components: [
    ButtonLoadingSpinner,
  ],
  async setup() {
    return {
      v$: useVuelidate(),
      safeGet: get,
      identityStore: useIdentityStore(),
    };
  },
  data() {
    return {
      ssnLastFour: '',
      submitting: false,
      showSsnLastFour: false,
    };
  },
  validations() {
    return {
      ssnLastFour: {
        required: required,
        numeric,
      },
    };
  },
  methods: {
    async validateAndSubmit() {
      this.v$.ssnLastFour.$touch();
      if (!this.v$.ssnLastFour.$invalid) {
        await this.verify()
      }
    },
    async verify() {
      this.submitting = true;
      try {
        await this.identityStore.verifySsn(this.ssnLastFour)
      } catch (error) {
        const errors = get(error, 'response.data.errors') ?? ['Verification failed. Please try again.']
        let errorMessage = errors[0]
        if (this.$refs?.ssnVerify?.$refs?.alert) {
          this.$refs.ssnVerify.$refs.alert.openDanger({
            content: errorMessage,
            untrusted: true
          })
        }
        this.submitting = false
      }
    },
  },
}
</script>
