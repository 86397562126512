<template>
  <div class="d-flex flex-grow-1 justify-content-center align-items-center">
    <Card>
      <template #title>
        Contact Lendio
      </template>
      <template #main>
        <p>Since you haven't set up multi-factor authentication yet, please reach out to a Lendio customer representative to reset your password.</p>
        <div class="mb-4">
          <a :href="`tel:${unformattedCustomerServiceNumber}`" class="cx-number">{{ customerServiceNumber }}</a>
        </div>
        <div class="fw-bold">Hours of operation:</div>
        <div class="mt-1">Monday - Friday</div>
        <div>7:30 AM - 5:00 PM MST</div>
      </template>
      <template #actions>
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary"
            @click="goToLogin">
            <ButtonLoadingSpinner v-if="submitting"></ButtonLoadingSpinner>Return to sign in
          </button>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from './Card'
import env from '~/libs/env'
import { phone } from '~/libs/format'
import { useIdentityStore } from '~/store/identity'

export default {
  name: 'IdentityContactUs',
  components: [
    Card
  ],
  async setup() {
    return {
      identityStore: useIdentityStore(),
      router
    }
  },
  data () {
    return {
      submitting: false
    }
  },
  methods: {
    async goToLogin () {
      this.submitting = true
      await navigateTo(this.identityStore.loginUrl)
    }
  },
  computed: {
    customerServiceNumber () {
      return phone(env('customerServicePhoneNumber'))
    },
    unformattedCustomerServiceNumber () {
      return env('customerServicePhoneNumber')
    }
  }
}
</script>

<style lang="scss" scoped>
.cx-number {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #2468C7;
}
</style>
